import { format } from 'date-fns/format';
import { useMemo } from 'react';

import { NegotiationEvent } from '@/modules/negotiation/api/queries/get-negotiation-events-query';
import { NegotiationEventType } from '@/modules/negotiation/api/resources/negotiation/negotiation-events-resource-struct';
import {
  EventFeedItem,
  EventFeedItemSizes,
} from '@/modules/negotiation/components/metrics/event-feed';
import { NegotiationType } from '@/modules/negotiation/types/negotiationTypes';

export const useNegotiationEventFeed = (
  events: NegotiationEvent[] = [],
  negotiationTypes: NegotiationType[] = []
) => {
  const eventsByDay = useMemo(
    () =>
      events?.reduce(
        (acc, event) => {
          const eventDate = new Date(event.event.timestamp);
          const dateKey = eventDate.toISOString().split('T')[0]; // 'yyyy-MM-dd'
          if (!acc[dateKey]) {
            acc[dateKey] = [];
          }

          if (!event.negotiationSupplier) {
            return acc;
          }

          const nsResponse =
            event.negotiationSupplier.attributes
              .negotiationSupplierResponses?.[0];

          const eventData = [];

          if (
            event.event.eventType === NegotiationEventType.SUPPLIER_ANSWERED
          ) {
            const { responseDetails } = event.event.payload;
            for (const negotiationType of negotiationTypes) {
              switch (negotiationType) {
                case NegotiationType.IMPROVED_PRICES:
                  eventData.push({
                    label: 'Discount',
                    value:
                      responseDetails?.discount && responseDetails.discount > 0
                        ? responseDetails.discount.toString() + '%'
                        : 'None',
                  });
                  break;
                case NegotiationType.LOCKED_PRICES:
                  eventData.push({
                    label: 'Locked price',
                    value: responseDetails?.lockedPrice
                      ? 'Accepted'
                      : 'Declined',
                  });
                  break;
                case NegotiationType.EXTRA_PAYMENT_DAYS:
                  eventData.push({
                    label: 'Extra payment days',
                    value: responseDetails?.acceptedExtraPaymentDays
                      ? 'Accepted'
                      : 'Declined',
                  });
                  break;
                case NegotiationType.CODE_OF_CONDUCT:
                  eventData.push({
                    label: 'Code of Conduct',
                    value: responseDetails?.acceptedCoc ? 'Signed' : 'Declined',
                  });
                  break;
                case NegotiationType.STANDARD_AGREEMENT:
                  eventData.push({
                    label: 'Standard agreement',
                    value: responseDetails?.acceptedStandardAgreement
                      ? 'Signed'
                      : 'Declined',
                  });
                  break;
                case NegotiationType.SUPPLIER_QUESTIONNAIRE:
                  if (responseDetails?.answeredQuestionnaire) {
                    eventData.push({
                      label: 'Supplier questionnaire',
                      value: 'Answered',
                    });
                  }
                  break;
                case NegotiationType.ISO:
                  if (responseDetails?.answeredIso) {
                    eventData.push({
                      label: 'ISO',
                      value: 'Answered',
                    });
                  }
                  break;
                case NegotiationType.PROLONG:
                  eventData.push({
                    label: 'Prolong',
                    value: responseDetails?.acceptedProlong
                      ? 'Accepted'
                      : 'Declined',
                  });
                  break;
                default:
                  break;
              }
            }

            if (nsResponse?.attributes.importantForMe) {
              eventData.push({
                label: 'Important for me',
                value: nsResponse?.attributes.importantForMe,
                size: EventFeedItemSizes.Large,
              });
            }

            if (nsResponse?.attributes.noResponseReason) {
              eventData.push({
                label: 'Reason',
                value: `${nsResponse?.attributes.noResponseReason} ${
                  (nsResponse?.attributes.noResponseRaw?.otherText as string) ??
                  ''
                }`,
                size: EventFeedItemSizes.Large,
              });
            }
          }

          if (
            event.event.eventType ===
            NegotiationEventType.SUPPLIER_ANSWER_UPDATED
          ) {
            const { responseDetails, previousResponseDetails } =
              event.event.payload;
            for (const negotiationType of negotiationTypes) {
              switch (negotiationType) {
                case NegotiationType.IMPROVED_PRICES:
                  if (
                    responseDetails?.discount &&
                    responseDetails?.discount > 0 &&
                    previousResponseDetails?.discount === 0
                  ) {
                    eventData.push({
                      label: 'Discount',
                      value: responseDetails.discount.toString() + '%',
                    });
                    break;
                  }

                  if (
                    responseDetails?.discount &&
                    responseDetails?.discount > 0 &&
                    responseDetails?.discount >
                      (previousResponseDetails?.discount || 0)
                  ) {
                    eventData.push({
                      label: 'Discount updated',
                      value: responseDetails.discount.toString() + '%',
                    });
                  }
                  break;
                case NegotiationType.LOCKED_PRICES:
                  if (
                    responseDetails?.lockedPrice &&
                    !previousResponseDetails?.lockedPrice
                  ) {
                    eventData.push({
                      label: 'Locked price',
                      value: 'Accepted',
                    });
                  }
                  break;
                case NegotiationType.EXTRA_PAYMENT_DAYS:
                  if (
                    responseDetails?.acceptedExtraPaymentDays &&
                    !previousResponseDetails?.acceptedExtraPaymentDays
                  ) {
                    eventData.push({
                      label: 'Extra payment days',
                      value: 'Accepted',
                    });
                  }
                  break;
                case NegotiationType.CODE_OF_CONDUCT:
                  if (
                    responseDetails?.acceptedCoc &&
                    !previousResponseDetails?.acceptedCoc
                  ) {
                    eventData.push({
                      label: 'Code of Conduct',
                      value: 'Signed',
                    });
                  }
                  break;
                case NegotiationType.STANDARD_AGREEMENT:
                  if (
                    responseDetails?.acceptedStandardAgreement &&
                    !previousResponseDetails?.acceptedStandardAgreement
                  ) {
                    eventData.push({
                      label: 'Standard agreement',
                      value: 'Signed',
                    });
                  }
                  break;
                case NegotiationType.SUPPLIER_QUESTIONNAIRE:
                  if (responseDetails?.answeredQuestionnaire) {
                    eventData.push({
                      label: 'Supplier questionnaire',
                      value: 'Answered',
                    });
                  }
                  break;
                case NegotiationType.ISO:
                  if (responseDetails?.answeredIso) {
                    eventData.push({
                      label: 'ISO',
                      value: 'Answered',
                    });
                  }
                  break;
                case NegotiationType.PROLONG:
                  if (
                    responseDetails?.acceptedProlong &&
                    !previousResponseDetails?.acceptedProlong
                  ) {
                    eventData.push({
                      label: 'Prolong',
                      value: 'Accepted',
                    });
                  }
                  break;
                default:
                  break;
              }
            }
          }

          acc[dateKey].push({
            supplier:
              event.negotiationSupplier?.attributes.freezedSupplierName ?? '',
            date: new Date(event.event.timestamp),
            eventData,
          });
          return acc;
        },
        {} as Record<string, EventFeedItem[]>
      ),
    [events, negotiationTypes]
  );

  // convert to array
  return Object.entries(eventsByDay).map(([date, events]) => ({
    date: format(date, 'EEEE - MMM dd'),
    events,
  }));
};
