import { subMonths } from 'date-fns/subMonths';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import generatePDF, { Margin, Resolution } from 'react-to-pdf';
import { useTheme } from 'styled-components';

import { ReactComponent as CalendarIcon } from '@/assets/icons/calendar.svg';
import { ReactComponent as LockedPricesIcon } from '@/assets/icons/locked-prices.svg';
import { ReactComponent as PiggyBankIcon } from '@/assets/icons/piggy-bank.svg';
import DateRangePicker from '@/components/atoms/date-range-picker/date-range-picker';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { AuthenticatedLayout } from '@/layouts/AuthenticatedLayout/AuthenticatedLayout';
import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import BoxHeaderTitle from '@/modules/negotiation/components/metrics/box-header-title';
import {
  NegotiationStateV2,
  NegotiationType,
} from '@/modules/negotiation/types/negotiationTypes';
import { HeaderButton } from '@/modules/theme/components/HeaderButton';
import { PageHeader } from '@/modules/theme/components/PageHeader/PageHeader';
import { PageMeta } from '@/modules/theme/components/PageMeta';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';
import { financialCompactFormatter } from '@/utils/financialCompactFormatter';

import OtherTermsBox from '../../modules/negotiation/components/metrics/other-terms-box';
import PriceMetricsBox from '../../modules/negotiation/components/metrics/price-metrics-box';
import SpendBox from '../../modules/negotiation/components/metrics/spend-box';

import { useGetNegotiationsDashboardMetrics } from './hooks/use-get-negotiation-dashboard-metrics.hook';
import { useGetNegotiationsSuppliersDashboardMetrics } from './hooks/use-get-negotiation-suppliers-dashboard-metrics.hook';

export const DashboardPage = () => {
  const { t } = useTranslation('pages/DashboardPage');
  const theme = useTheme();
  const {
    customer: { id: customerId, currency },
  } = useSessionContext();
  const [startDate, setStartDate] = useState<Date | undefined>(
    subMonths(new Date(), 6)
  );
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);

  const handleDateRangeChange = (start: Date | undefined, end: Date) => {
    setStartDate(start);
    setEndDate(end);
  };

  const { data: metrics, isPending } = useGetNegotiationsDashboardMetrics({
    customerId,
    negotiationState: NegotiationStateV2.COMPLETED,
    after: startDate?.toISOString(),
    before: endDate?.toISOString(),
  });

  const { data: supplierMetrics, isPending: isSupplierMetricsPending } =
    useGetNegotiationsSuppliersDashboardMetrics({
      customerId,
      negotiationState: NegotiationStateV2.COMPLETED,
      after: startDate?.toISOString(),
      before: endDate?.toISOString(),
    });

  const getTargetElement = () => document.getElementById('dashboard-content');

  const downloadAsPdf = async () => {
    try {
      await generatePDF(getTargetElement, {
        filename: `negotiation-results.pdf`,
        resolution: Resolution.MEDIUM,
        page: {
          margin: Margin.MEDIUM,
          format: 'letter',
          orientation: 'landscape',
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Failed to generate PDF', error);
    }
  };

  return (
    <AuthenticatedLayout>
      <PageMeta title={t('Dashboard')} description={t('Dashboard')} />
      <PageHeader
        text={t('Dashboard')}
        buttonLink={
          <HeaderButton onClick={downloadAsPdf}>
            {t('Download as PDF')}
          </HeaderButton>
        }
      />
      <div
        id="dashboard-content"
        className="relative p-5 md:p-6 lg:p-10 md:p-5 md:py-2 md:px-2 lg:py-4 lg:px-4"
      >
        {isPending || isSupplierMetricsPending ? (
          <SkeletonLoader lines={3} height={260} />
        ) : (
          metrics && (
            <>
              <div className="flex flex-row flex-wrap gap-4 mb-4 items-center">
                <div className="lg:w-[33%] min-w-[350px] max-w-[500px] flex-1">
                  <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onDateRangeChange={handleDateRangeChange}
                    noStartDateTitle="First negotiation"
                  />
                </div>
                <div>
                  <Button
                    variant="default"
                    size="sm"
                    onClick={() => {
                      setStartDate(undefined);
                      setEndDate(new Date());
                    }}
                  >
                    All negotiations
                  </Button>
                </div>
              </div>
              <div className="flex flex-row flex-wrap gap-4">
                <SpendBox
                  className="flex-[1_0_30%] min-w-[350px]"
                  title={t('Total generated value')}
                  label="value"
                  currency={currency}
                  items={[
                    {
                      label: t('Improved prices'),
                      color: theme.palette.tertiary.main,
                      value: metrics.value.improvedPricesValue,
                    },
                    {
                      label: t('Locked prices'),
                      color: theme.palette.secondary.main,
                      value: metrics.value.lockedPricesValue,
                    },
                    {
                      label: t('Payment terms'),
                      color: theme.palette.primary.main,
                      value: metrics.value.extraPaymentDaysValue,
                    },
                  ]}
                />
                <SpendBox
                  className="flex-[1_0_30%] min-w-[350px]"
                  title={t('Invited suppliers')}
                  label="value"
                  description="Unique suppliers"
                  items={[
                    {
                      label: t('Addressed - Answered'),
                      color: theme.palette.tertiary.main,
                      value: supplierMetrics?.uniqueSuppliersAnswered,
                    },
                    {
                      label: t('Addressed - Not answered'),
                      color: theme.palette.secondary.main,
                      value:
                        (supplierMetrics?.uniqueSuppliersActive || 0) -
                        (supplierMetrics?.uniqueSuppliersAnswered || 0),
                    },
                    {
                      label: t('Invited - Not active'),
                      color: theme.palette.primary.main,
                      value:
                        (supplierMetrics?.uniqueSuppliersInvited || 0) -
                        (supplierMetrics?.uniqueSuppliersActive || 0),
                    },
                  ]}
                />
                <Card className="flex-[1_0_30%] min-w-[350px]">
                  <BoxHeaderTitle title="Negotiations" />
                  <CardContent className="flex flex-col gap-2 h-full items-center justify-center">
                    <span className="text-[100px] font-semibold tracking-[-0.88px] text-[#4ba5b4]">
                      {supplierMetrics?.negotiationCount || 0}
                    </span>
                    <span className="text-base font-semibold tracking-[-0.35px] text-[#4ba5b4] mb-6">
                      {t('Completed negotiations')}
                    </span>
                  </CardContent>
                </Card>
                <PriceMetricsBox
                  className="flex-[1_0_30%] min-w-[350px]"
                  title={t('Improved prices')}
                  mainValue={financialCompactFormatter.formatFull(
                    metrics.value.improvedPricesValue
                  )}
                  mainLabel={`${t('Savings')} ${currency}`}
                  metrics={[
                    {
                      value:
                        metrics.improvedPrices.improvedPricesInvited.toString(),
                      label: 'Invited\nSuppliers',
                    },
                    {
                      value:
                        metrics.improvedPrices.improvedPricesInvited > 0
                          ? `${financialCompactFormatter.formatNumberWithDecimals(
                              (metrics.improvedPrices.improvedPricesAccepted /
                                metrics.improvedPrices.improvedPricesInvited) *
                                100
                            )}%`
                          : '0%',

                      label: 'Acceptance\nrate',
                    },
                    {
                      value: `${financialCompactFormatter.formatNumberWithDecimals(
                        metrics.improvedPrices.averageDiscount || 0
                      )}%`,
                      label: 'Average\ndiscount',
                    },
                  ]}
                  icon={PiggyBankIcon}
                  active={metrics.value.improvedPricesValue > 0}
                />
                <PriceMetricsBox
                  className="flex-[1_0_30%] min-w-[350px]"
                  title={t('Locked prices')}
                  mainValue={financialCompactFormatter.formatFull(
                    metrics.value.lockedPricesValue
                  )}
                  mainLabel={`${t('Savings')} ${currency}`}
                  metrics={[
                    {
                      value:
                        metrics.lockedPrices.lockedPricesInvited.toString(),
                      label: 'Invited\nSuppliers',
                    },
                    {
                      value:
                        metrics.lockedPrices.lockedPricesInvited > 0
                          ? `${financialCompactFormatter.formatNumberWithDecimals(
                              (metrics.lockedPrices.lockedPricesAccepted /
                                metrics.lockedPrices.lockedPricesInvited) *
                                100,
                              0
                            )}%`
                          : '0%',
                      label: 'Acceptance\nrate',
                    },
                    {
                      value:
                        metrics.lockedPrices.lockedPricesInvited > 0
                          ? `${financialCompactFormatter.formatNumberWithDecimals(
                              (metrics.value.lockedPricesValue /
                                metrics.lockedPrices.lockedPricesActiveSpend) *
                                100
                            )}%`
                          : '0%',
                      label: 'Cost avoidance on addressed baseline',
                    },
                  ]}
                  icon={LockedPricesIcon}
                  active={metrics.lockedPrices.lockedPricesInvited > 0}
                />
                <PriceMetricsBox
                  className="flex-[1_0_30%] min-w-[350px]"
                  title={t('Improved payment terms')}
                  mainValue={financialCompactFormatter.formatFull(
                    metrics.value.extraPaymentDaysValue
                  )}
                  mainLabel={`${t('Value')} ${currency}`}
                  metrics={[
                    {
                      value:
                        metrics.extraPaymentDays.extraPaymentDaysInvited.toString(),
                      label: 'Invited\nSuppliers',
                    },
                    {
                      value:
                        metrics.extraPaymentDays.extraPaymentDaysInvited > 0
                          ? `${financialCompactFormatter.formatNumberWithDecimals(
                              (metrics.extraPaymentDays
                                .extraPaymentDaysAccepted /
                                metrics.extraPaymentDays
                                  .extraPaymentDaysInvited) *
                                100,
                              0
                            )}%`
                          : '0%',
                      label: 'Acceptance\nrate',
                    },
                    {
                      value: financialCompactFormatter.format(
                        metrics.extraPaymentDays.workingCapitalImprovement
                      ),
                      label: 'Working capital\nimprovement',
                    },
                  ]}
                  icon={CalendarIcon}
                  active={metrics.extraPaymentDays.extraPaymentDaysInvited > 0}
                />
                <OtherTermsBox
                  className="flex-[1_0_48%] min-w-[350px]"
                  termsCategories={[
                    {
                      title: 'Code of conduct',
                      count: metrics.coc.cocAccepted,
                      isActive: metrics.coc.cocInvited > 0,
                      acceptanceRate:
                        metrics.coc.cocInvited > 0
                          ? financialCompactFormatter.formatNumberWithDecimals(
                              (metrics.coc.cocAccepted /
                                metrics.coc.cocInvited) *
                                100
                            )
                          : undefined,
                      invitedSuppliers: metrics.coc.cocInvited,
                      negotiationType: NegotiationType.CODE_OF_CONDUCT,
                    },
                    {
                      title: 'Standard agreement',
                      count:
                        metrics.standardAgreement.standardAgreementAccepted,
                      isActive:
                        metrics.standardAgreement.standardAgreementInvited > 0,
                      acceptanceRate:
                        metrics.standardAgreement.standardAgreementInvited > 0
                          ? financialCompactFormatter.formatNumberWithDecimals(
                              (metrics.standardAgreement
                                .standardAgreementAccepted /
                                metrics.standardAgreement
                                  .standardAgreementInvited) *
                                100
                            )
                          : undefined,
                      invitedSuppliers:
                        metrics.standardAgreement.standardAgreementInvited,
                      negotiationType: NegotiationType.STANDARD_AGREEMENT,
                    },
                    {
                      title: 'Questionnaire',
                      count: metrics.questionnaire.questionnaireAnswers,
                      isActive: metrics.questionnaire.questionnaireInvited > 0,
                      acceptanceRate:
                        metrics.standardAgreement.standardAgreementInvited > 0
                          ? financialCompactFormatter.formatNumberWithDecimals(
                              (metrics.questionnaire.questionnaireAnswers /
                                metrics.questionnaire.questionnaireInvited) *
                                100
                            )
                          : undefined,
                      invitedSuppliers:
                        metrics.questionnaire.questionnaireInvited,
                      negotiationType: NegotiationType.SUPPLIER_QUESTIONNAIRE,
                    },
                  ]}
                />
              </div>
            </>
          )
        )}
      </div>
    </AuthenticatedLayout>
  );
};
