import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MultiSelect } from '@/modules/form/components/Select/MultiSelect';

type SupplierBusinessUnitFilterProps = {
  businessUnits: string[];
  filterValues: string[];
  onChange: (value: string[]) => void;
};

export const SupplierBusinessUnitFilter = ({
  filterValues,
  businessUnits,
  onChange,
}: SupplierBusinessUnitFilterProps) => {
  const { t } = useTranslation('modules/suppliers/SuppliersFilters');

  const options = useMemo(() => {
    return (
      businessUnits && [
        { label: t('No business unit'), value: 'no-business-unit' },
        ...businessUnits.map((businessUnit) => ({
          label: businessUnit,
          value: businessUnit.toLocaleLowerCase().replaceAll(' ', '-'),
        })),
      ]
    );
  }, [t, businessUnits]);

  const values = useMemo(() => {
    return filterValues.map((businessUnit) => ({
      label: businessUnit,
      value: businessUnit.toLocaleLowerCase().replaceAll(' ', '-'),
    }));
  }, [filterValues]);

  return (
    <MultiSelect
      name="businessUnit"
      label={t('Filter by business unit')}
      options={options}
      showErrorMessage={false}
      placeholder={t('Business unit')}
      onChange={(options) => onChange(options.map(({ label }) => label))}
      value={values}
    />
  );
};
