import { NegotiationState } from '../../../types/negotiationTypes';

export const negotiationResourceType = 'negotiation';

export const negotiationRelationshipsKeys = {
  metrics: 'metrics',
  communication: 'communication',
  terms: 'terms',
} as const;

export type GetNegotiationsQueryParams = {
  pageNumber: number;
  pageSize: number;
  states?: NegotiationState[];
};

export type GetNegotiationsQueryOptions = {
  refetchOnWindowFocus: boolean;
  staleTime: number;
  refetchInterval: number;
};

export const getNegotiationsResourcePath = (
  params: GetNegotiationsQueryParams
) => {
  const stateFilter = params.states?.length
    ? `&filter[state]=${params.states.join(',')}`
    : '';
  return `/me/customer/negotiations?page[number]=${params.pageNumber}&page[size]=${params.pageSize}&include=metrics${stateFilter}`;
};

export const getNegotiationsResourcePathV2 = () => {
  return `/v2/negotiations`;
};

export const getNegotiationResourcePath = (
  id: string,
  includes: Array<keyof typeof negotiationRelationshipsKeys>
) => {
  return `me/customer/negotiations/${id}?include=${includes.join(',')}`;
};

export const getUpdateNegotiationResourcePath = (id: string) => {
  return `/me/customer/negotiations/${id}`;
};

export const getNegotiationResourcePathV2 = (id: string) => {
  return `/v2/negotiations/${id}`;
};

export const getNegotiationEmlFileResourcePathV2 = (id: string) => {
  return `/v2/negotiations/${id}/intro_emails.zip`;
};

export const getNegotiationContractResourcePathV2 = (id: string) => {
  return `/v2/negotiations/${id}/contract`;
};

export const updateNegotiationSupplierResourcePathV2 = (id: string) => {
  return `/v2/negotiations/${id}/update-supplier`;
};

export const getNegotiationKeywordsResourcePathV2 = (id: string) => {
  return `/v2/negotiations/${id}/keywords`;
};

export const updateNegotiationKeywordResourcePathV2 = (
  negotiationId: string,
  keywordId: string
) => {
  return `/v2/negotiations/${negotiationId}/keywords/${keywordId}`;
};

export const getUpdateNegotiationResourcePathV2 = (id: string) => {
  return `/v2/negotiations/${id}`;
};

export const getSetNegotiationStateToSetupPath = (id: string) => {
  return `/me/customer/negotiations/${id}/start_setup`;
};

export const getNegotiationSupplierResponsePDFResourcePathV2 = () => {
  return `/v2/negotiation-suppliers/responses/export`;
};

export const getNegotiationSupplierResponsesPDFResourcePathV2 = () => {
  return `/v2/negotiation-suppliers/responses/export-accepted`;
};
