import styled from 'styled-components';

import { MaxWidth } from '@/modules/theme/components/MaxWidth';

import { SkeletonLoader } from '../SkeletonLoader/SkeletonLoader';

export const MaxWidthStyled = styled(MaxWidth)(
  ({ theme: { spacing, breakpoints } }) => ({
    padding: spacing([2, 8, 2, 8]),
    display: 'flex',
    minHeight: spacing(11),
    gap: spacing(9),
    alignItems: 'center',
    [breakpoints.down.lg]: {
      gap: spacing(4),
    },

    [breakpoints.down.md]: {
      flexDirection: 'column',
      gap: spacing(2),
    },
  })
);

export type PageHeaderVariant = 'color' | 'grey';

type HeaderStyledProps = {
  variant?: PageHeaderVariant;
};

export const HeaderStyled = styled('header')<HeaderStyledProps>(
  ({ variant, theme: { gradients } }) => ({
    background:
      variant === 'grey' ? gradients.header.grey : gradients.header.color,
  })
);

export const SkeletonLoaderStyled = styled(SkeletonLoader)({
  height: 20,
  width: 200,
});
