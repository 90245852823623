import type { PDFDocumentProxy } from 'pdfjs-dist';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf';

import { NegotiationSupplier } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import { useGetNegotiationSupplierResponsePDFQuery } from '@/modules/negotiation/hooks/useGetNegotiationSupplierResponsePDFQuery';
import { downloadFileFromUrl } from '@/modules/negotiation/utils/download-file-from-url';
import { Scrollbar } from '@/modules/theme/components/Scrollbar';

import {
  ButtonStyled,
  ContentWrapperStyled,
  DialogStyled,
  HeadingStyled,
} from './SuppliersActionsDialog.styled';

type SuppliersActionsDialogProps = {
  isOpen: boolean;
  negotiationId: string;
  onClose: () => void;
  supplier?: NegotiationSupplier;
};

export const SuppliersActionsDialog = ({
  isOpen,
  negotiationId,
  onClose,
  supplier,
}: SuppliersActionsDialogProps) => {
  const { t } = useTranslation('modules/negotiations/NegotiationSuppliersList');
  const containerRef = useRef<HTMLDivElement>(null);
  const [pdfAsUrl, setPdfAsUrl] = useState<string>();
  const [pagesCount, setPagesCount] = useState<number>(0);
  const [containerWidth, setContainerWidth] = useState(0);

  const { refetch } = useGetNegotiationSupplierResponsePDFQuery({
    negotiationId,
    negotiationSupplierId: supplier?.id ?? '',
  });

  const downloadResponseFile = () => {
    try {
      if (pdfAsUrl) {
        const fileName = `${(
          supplier?.attributes.freezedSupplierName ?? 'supplier'
        ).replace(/\s+/g, '-')}-responses.pdf`;

        downloadFileFromUrl(pdfAsUrl, fileName);
      } else {
        // eslint-disable-next-line no-console
        console.error('Failed to download file');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error downloading file:', error);
    }
  };

  useEffect(() => {
    const element = containerRef?.current;

    if (!element) return;

    const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      const [entry] = entries;

      if (entry) {
        setContainerWidth(entry.target.getBoundingClientRect().width);
      }
    });

    observer.observe(element);
    const getResponseFile = async () => {
      try {
        if (pdfAsUrl) {
          window.URL.revokeObjectURL(pdfAsUrl);
          setPdfAsUrl('');
        }

        const response = await refetch();

        if (response.isSuccess) {
          const url = window.URL.createObjectURL(response.data);
          setPdfAsUrl(url);
        } else {
          // eslint-disable-next-line no-console
          console.error('Failed to get file:', response.error);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error getting file:', error);
      }
    };
    void getResponseFile();

    return () => {
      observer.disconnect();
    };
  }, [supplier?.id]);

  const onDocumentLoadSuccess = (loadedDocument: PDFDocumentProxy) => {
    setPagesCount(loadedDocument.numPages);
  };

  if (!supplier) return null;

  return (
    <DialogStyled isOpen={isOpen} hasCloseIcon onClose={onClose}>
      <HeadingStyled variant="h2" mb={6}>
        {supplier.attributes.freezedSupplierName}
      </HeadingStyled>
      <HeadingStyled variant="h3" mb={2}>
        {t('Answers')}
      </HeadingStyled>
      <ContentWrapperStyled ref={containerRef}>
        <Scrollbar>
          <Document file={pdfAsUrl} onLoadSuccess={onDocumentLoadSuccess}>
            {pagesCount && pagesCount > 1 ? (
              Array.from({ length: pagesCount }, (_, index) => (
                <Page
                  key={index}
                  pageNumber={index + 1}
                  width={containerWidth}
                />
              ))
            ) : (
              <Page pageNumber={1} width={containerWidth} />
            )}
          </Document>
        </Scrollbar>
      </ContentWrapperStyled>
      <div>
        <ButtonStyled variant="outline" onClick={downloadResponseFile}>
          {t('Download as PDF')}
        </ButtonStyled>
        <ButtonStyled variant="outline" onClick={onClose}>
          {t('Back')}
        </ButtonStyled>
      </div>
    </DialogStyled>
  );
};
