import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { Button, ButtonProps } from '@/modules/theme/components/Button/Button';
import { ButtonLink, LinkType } from '@/modules/theme/components/ButtonLink';
import { halfTransparent, white } from '@/modules/theme/utils/colors';

type HeaderButtonLinkProps = {
  to: string;
  linkType?: LinkType;
  children: ReactNode;
  onClick?: () => void;
};

export const HeaderButtonLink = ({
  to,
  children,
  linkType = LinkType.Internal,
  onClick,
}: HeaderButtonLinkProps) => {
  return (
    <ButtonLinkStyled
      size={'small'}
      variant={'outline'}
      to={to}
      linkType={linkType}
      onClick={onClick}
    >
      {children}
    </ButtonLinkStyled>
  );
};

export const HeaderButton = ({ children, ...props }: ButtonProps) => {
  return (
    <ButtonStyled {...props} variant="outline">
      {children}
    </ButtonStyled>
  );
};

const buttonStyles = css(({ theme: { spacing, breakpoints } }) => ({
  color: white,
  borderColor: white,
  '&:disabled': {
    color: white + halfTransparent,
    borderColor: white + halfTransparent,
  },
  minHeight: spacing(5.5),
  minWidth: spacing(21.6),
  padding: 'unset',
  marginLeft: 'auto',
  '&:not([disabled])': {
    '&:hover': {
      borderColor: white,
      color: white,
    },
    '&:focus, &:active': {
      borderColor: white,
      color: white,
    },
  },
  [breakpoints.down.md]: {
    marginTop: spacing(1),
    marginLeft: 'unset',
  },
}));

const ButtonLinkStyled = styled(ButtonLink)(() => buttonStyles);

const ButtonStyled = styled(Button)(() => buttonStyles);
