import { useCallback } from 'react';

import {
  NegotiationSupplierStateV2,
  NegotiationWithProgressSuppliersOrder,
} from '@/modules/negotiation/types/negotiationTypes';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { useDebouncedState } from '@/utils/useDebounced';

type UseSetSuppliersQueryParamsPayload = {
  searchQuery: string;
  isNegotiationActive: boolean;
};

export const useSetSuppliersQueryParams = ({
  searchQuery,
  isNegotiationActive,
}: UseSetSuppliersQueryParamsPayload) => {
  const setQueryParams = onboardedRoutes.useSetQueryParams(
    isNegotiationActive ? 'activeNegotiation' : 'completedNegotiation'
  );

  const [debouncedSearchQuery, setDebouncedSearchQuery] = useDebouncedState(
    searchQuery,
    { wait: 1000 }
  );

  const setPageNumber = useCallback(
    (pageNumber: number) =>
      setQueryParams({
        'suppliers.page': pageNumber,
      }),
    [setQueryParams]
  );

  const setPageSize = useCallback(
    (pageSize: number) => {
      setQueryParams({
        'suppliers.size': pageSize,
        'suppliers.page': 1,
      });
    },
    [setQueryParams]
  );

  const setSearchQuery = useCallback(
    (query: string) => {
      setQueryParams({
        'suppliers.searchQuery': query || undefined,
        'suppliers.page': 1,
      });
      setDebouncedSearchQuery(query);
    },
    [setQueryParams, setDebouncedSearchQuery]
  );

  const setCategories = useCallback(
    (categories: string[]) => {
      setQueryParams({
        'suppliers.category': categories?.length ? categories : undefined,
        'suppliers.page': 1,
      });
    },
    [setQueryParams]
  );

  const setBusinessUnits = useCallback(
    (businessUnits: string[]) => {
      // eslint-disable-next-line no-console
      console.log('>>> setBusinessUnits', businessUnits);
      setQueryParams({
        'suppliers.businessUnits': businessUnits?.length
          ? businessUnits
          : undefined,
        'suppliers.page': 1,
      });
    },
    [setQueryParams]
  );

  const setOrder = useCallback(
    (sortRule: NegotiationWithProgressSuppliersOrder) => {
      setQueryParams({ 'suppliers.sort': sortRule });
    },
    [setQueryParams]
  );

  const setProgressStep = useCallback(
    (step: NegotiationSupplierStateV2 | 'null' | undefined) => {
      if (step === 'null') {
        setQueryParams({ 'suppliers.progressStep': undefined });
      } else {
        setQueryParams({ 'suppliers.progressStep': step });
      }
    },
    [setQueryParams]
  );

  return {
    debouncedSearchQuery,
    setSearchQuery,
    setProgressStep,
    setOrder,
    setCategories,
    setPageSize,
    setPageNumber,
    setBusinessUnits,
  };
};
