import * as sup from 'superstruct';

import { dateTime } from '@/utils/structs/structs';

import {
  NegotiationSupplierStateV2,
  NegotiationType,
} from '../../../types/negotiationTypes';

export const negotiationSupplierStateStruct = sup.enums(
  Object.values(NegotiationSupplierStateV2)
);

export const negotiationTypeRolesStruct = sup.enums(
  Object.values(NegotiationType)
);

export const negotiationSupplierSupplier = sup.object({
  id: sup.string(),
  name: sup.optional(sup.string()),
  spend: sup.optional(sup.number()),
  category: sup.optional(sup.string()),
  businessUnit: sup.optional(sup.string()),
  defaultContact: sup.optional(
    sup.object({
      id: sup.string(),
      firstName: sup.optional(sup.string()),
      lastName: sup.optional(sup.string()),
      email: sup.optional(sup.string()),
      phoneNumber: sup.optional(sup.string()),
    })
  ),
});

export const negotiationSupplierRawResponseStruct = sup.record(
  sup.string(),
  sup.object({
    id: sup.string(),
    value: sup.optional(sup.string()),
    type: sup.optional(sup.string()),
    position: sup.optional(sup.string()),
  })
);

export const negotiationSupplierResponseStruct = sup.object({
  id: sup.string(),
  type: sup.string(),
  attributes: sup.object({
    acceptedCoc: sup.optional(sup.boolean()),
    acceptedExtraPaymentDays: sup.optional(sup.boolean()),
    acceptedStandardAgreement: sup.optional(sup.boolean()),
    answeredIso: sup.optional(sup.boolean()),
    answeredQuestionnaire: sup.optional(sup.boolean()),
    discount: sup.optional(sup.number()),
    lockedPrice: sup.optional(sup.boolean()),
    noResponseReason: sup.optional(sup.string()),
    noResponseRaw: sup.optional(sup.record(sup.string(), sup.any())),
    importantForMe: sup.optional(sup.string()),
    supplierContactId: sup.optional(sup.string()),
    rawResponse: negotiationSupplierRawResponseStruct,
    deletedAt: sup.optional(dateTime),
    createdAt: dateTime,
  }),
});

export const negotiationSupplierContactStruct = sup.object({
  id: sup.string(),
  type: sup.string(),
  attributes: sup.object({
    supplierContactId: sup.optional(sup.string()),
    freezedSupplierContact: sup.optional(sup.string()),
    freezedSupplierEmail: sup.optional(sup.string()),
    freezedSupplierPhone: sup.optional(sup.string()),
  }),
});

export const negotiationSupplierStruct = sup.object({
  id: sup.string(),
  type: sup.string(),
  attributes: sup.object({
    supplierId: sup.string(),
    negotiationId: sup.optional(sup.string()),
    defaultSupplierContactId: sup.optional(sup.nullable(sup.string())),
    freezedSupplierName: sup.optional(sup.nullable(sup.string())),
    freezedSupplierEmail: sup.optional(sup.nullable(sup.string())),
    freezedSupplierSpend: sup.optional(sup.nullable(sup.number())),
    negotiationSupplierState: sup.optional(sup.string()),
    supplier: sup.optional(negotiationSupplierSupplier),
    negotiationSupplierResponses: sup.optional(
      sup.array(negotiationSupplierResponseStruct)
    ),
    negotiationSupplierContacts: sup.optional(
      sup.array(negotiationSupplierContactStruct)
    ),
  }),
});

export type NegotiationSupplier = sup.Infer<typeof negotiationSupplierStruct>;

export const activeNegotiationMetricsStruct = sup.object({
  id: sup.string(),
  type: sup.string(),
  attributes: sup.object({
    spend: sup.object({
      total: sup.number(),
      active: sup.number(),
      completed: sup.number(),
      accepted: sup.number(),
      discount: sup.number(),
      lockedPrice: sup.number(),
      acceptedExtraPaymentDays: sup.number(),
      acceptedStandardAgreement: sup.number(),
      acceptedCoc: sup.number(),
      acceptedProlong: sup.number(),
      answeredQuestionnaire: sup.number(),
      answeredIso: sup.number(),
      supplierRegistration: sup.number(),
    }),
    supplierStatesSpend: sup.record(
      sup.enums(Object.values(NegotiationSupplierStateV2)),
      sup.number()
    ),
    improvedPricesValue: sup.number(),
    lockedPricesValue: sup.number(),
    extraPaymentDaysValue: sup.number(),
    workingCapitalImprovement: sup.number(),
    counts: sup.object({
      accepted: sup.number(),
      responses: sup.number(),
      discount: sup.number(),
      lockedPrice: sup.number(),
      acceptedExtraPaymentDays: sup.number(),
      acceptedStandardAgreement: sup.number(),
      acceptedCoc: sup.number(),
      acceptedProlong: sup.number(),
      answeredQuestionnaire: sup.number(),
      answeredIso: sup.number(),
      noResponseReason: sup.number(),
      supplierRegistration: sup.number(),
    }),
    supplierStatesCount: sup.record(
      sup.enums(Object.values(NegotiationSupplierStateV2)),
      sup.number()
    ),
    averageDiscount: sup.optional(sup.number()),
  }),
});

export type ActiveNegotiationMetricsType = sup.Infer<
  typeof activeNegotiationMetricsStruct
>;

export const negotiationResourceStructV2 = sup.object({
  id: sup.string(),
  type: sup.string(),
  attributes: sup.object({
    name: sup.string(),
    state: sup.string(),
    type: sup.array(sup.string()),
    currency: sup.string(),
    customerId: sup.string(),
    createdAt: dateTime,
    deleatedAt: sup.optional(dateTime),
    wpId: sup.optional(sup.string()),
    demoSpend: sup.optional(sup.number()),
    totalSpend: sup.optional(sup.number()),
    demoSupplierNumber: sup.optional(sup.number()),
    improvedPricesEstimate: sup.optional(sup.number()),
    lockedPricesEstimate: sup.optional(sup.number()),
    extraPaymentDays: sup.optional(sup.number()),
    wpContractId: sup.optional(sup.string()),
    negotiationSuppliers: sup.optional(sup.array(negotiationSupplierStruct)),
    wpTemplateFlowId: sup.optional(sup.string()),
    wpCommunicationPresetId: sup.optional(sup.string()),
    suggestedPeriod: sup.optional(sup.number()),
    suggestedPeriodIndex: sup.optional(sup.number()),
    startDate: sup.optional(dateTime),
    endDate: sup.optional(dateTime),
    negotiationMetrics: sup.optional(activeNegotiationMetricsStruct),
    emailCount: sup.optional(sup.number()),
  }),
});

export type NegotiationResourceTypeV2 = sup.Infer<
  typeof negotiationResourceStructV2
>;

export const updateNegotiationSupplierStruct = sup.object({
  negotiationSupplierState: sup.optional(negotiationSupplierStateStruct),
});

export type UpdateNegotiationSupplierStruct = sup.Infer<
  typeof updateNegotiationSupplierStruct
>;
