import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as MinusIcon } from '@/assets/icons/minusIcon.svg';
import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { ParsedSuppliers } from '@/modules/suppliers/helpers/parseGetSuppliersResponse';
import {
  Order,
  SortRule,
  SupplierTableColumn,
} from '@/modules/suppliers/types/supplierTypes';
import { ActionTableCell } from '@/modules/theme/components/Table/ActionTableCell.styled';
import {
  TableHeader,
  TableHeaderCell,
} from '@/modules/theme/components/Table/Table.styled';
import { TableCell } from '@/modules/theme/components/Table/TableCell';
import { TableSkeletonLoader } from '@/modules/theme/components/TableSkeletonLoader/TableSkeletonLoader';
import { Heading, Text } from '@/modules/theme/components/Typography';
import { dark } from '@/modules/theme/utils/colors';
import { formatAmount } from '@/utils/stringUtils';

import { SetSortRule } from '../../hooks/useHandleSuppliersRoutingPagination';

import { DeleteSupplierDialog } from './DeleteSupplierDialog';
import {
  DeleteButtonStyled,
  EditIconStyled,
  LinkStyled,
  NoSuppliersFoundContainer,
  RowLinkStyled,
  TableRowStyled,
  TableStyled,
} from './SuppliersTable.styled';
import { SuppliersTableHeaderCell } from './SuppliersTableHeaderCell';

type SupplierTableProps = {
  suppliers: ParsedSuppliers['suppliers'] | undefined;
  sortRule: SortRule;
  setSortRule: SetSortRule;
  isLoading: boolean;
  pageSize: number;
};

export type RemoveSupplierDialogState = {
  isOpen: boolean;
  companyName: string | null;
  supplierId: string | null;
};

const initialRemoveSupplierDialogState: RemoveSupplierDialogState = {
  isOpen: false,
  companyName: null,
  supplierId: null,
};

export const SuppliersTable = ({
  suppliers,
  sortRule,
  setSortRule,
  isLoading,
  pageSize,
}: SupplierTableProps) => {
  const { t } = useTranslation('pages/SuppliersPage');
  const {
    customer: { currency },
  } = useSessionContext();
  const [removeSupplierDialogState, setRemoveSupplierDialogState] =
    useState<RemoveSupplierDialogState>(initialRemoveSupplierDialogState);

  const headers = [
    { key: SupplierTableColumn.COMPANY, name: t('Company') },
    {
      key: SupplierTableColumn.SPEND,
      name: t('Spend ({{currency}})', { currency }),
    },
    { key: SupplierTableColumn.CATEGORY, name: t('Category') },
  ];

  const handleSortRuleChange = (column: SupplierTableColumn) => {
    if (sortRule?.column !== column) {
      return setSortRule({ column, order: Order.asc });
    }
    if (sortRule.order === Order.desc) {
      return setSortRule({ column: null, order: null });
    }
    return setSortRule({ column, order: Order.desc });
  };

  if (!isLoading && !suppliers?.length) {
    return (
      <NoSuppliersFoundContainer>
        <Heading variant="h3" color={dark[400]}>
          {t('No suppliers found')}
        </Heading>
      </NoSuppliersFoundContainer>
    );
  }

  return (
    <>
      <TableStyled columnsCount={4}>
        <TableHeader>
          {headers.map(({ key, name }) => (
            <SuppliersTableHeaderCell
              key={key}
              columnId={key}
              sortRule={sortRule}
              onClick={handleSortRuleChange}
            >
              {name}
            </SuppliersTableHeaderCell>
          ))}
          <TableHeaderCell />
        </TableHeader>

        {isLoading ? (
          <TableSkeletonLoader rows={pageSize} />
        ) : (
          suppliers &&
          suppliers.map((supplier) => (
            <TableRowStyled key={supplier.id}>
              <RowLinkStyled
                to={onboardedRoutes.buildUrl('supplier', {
                  params: { supplierId: supplier.id },
                })}
              >
                <TableCell
                  mobileHeader={getHeader(SupplierTableColumn.COMPANY, headers)}
                >
                  <Text>{supplier.name}</Text>
                </TableCell>
                <TableCell
                  mobileHeader={getHeader(SupplierTableColumn.SPEND, headers)}
                >
                  <Text>{formatAmount(Number(supplier.currentSpend))}</Text>
                </TableCell>
                <TableCell
                  mobileHeader={getHeader(
                    SupplierTableColumn.CATEGORY,
                    headers
                  )}
                >
                  <Text>{supplier.category?.name}</Text>
                </TableCell>
              </RowLinkStyled>
              <ActionTableCell>
                <LinkStyled
                  title={t('Edit')}
                  to={onboardedRoutes.buildUrl('editSupplier', {
                    params: { supplierId: supplier.id },
                  })}
                  onClick={(e) => e.stopPropagation()}
                >
                  <EditIconStyled />
                </LinkStyled>
                <DeleteButtonStyled
                  title={t('Delete')}
                  onClick={(e) => {
                    e.stopPropagation();
                    setRemoveSupplierDialogState({
                      isOpen: true,
                      companyName: supplier.name,
                      supplierId: supplier.id,
                    });
                  }}
                >
                  <MinusIcon />
                </DeleteButtonStyled>
              </ActionTableCell>
            </TableRowStyled>
          ))
        )}
      </TableStyled>
      <DeleteSupplierDialog
        removeSupplierDialogState={removeSupplierDialogState}
        onClose={() =>
          setRemoveSupplierDialogState(initialRemoveSupplierDialogState)
        }
      />
    </>
  );
};

const getHeader = (
  key: SupplierTableColumn,
  headers: { key: SupplierTableColumn; name: string }[]
) => {
  return headers.find((column) => column.key === key)?.name;
};
