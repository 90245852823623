import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as sup from 'superstruct';

import { ReactComponent as DownloadIcon } from '@/assets/icons/downloadIcon.svg';
import { AuthenticatedLayout } from '@/layouts/AuthenticatedLayout/AuthenticatedLayout';
import { useForm } from '@/modules/form/components/Form/hooks/useForm/useForm';
import { TextInput } from '@/modules/form/components/TextInput/TextInput';
import { useGetNegotiationEmlFileV2Query } from '@/modules/negotiation/hooks/useGetNegotiationEmlFileV2Query';
import { useGetNegotiationKeywordsQueryV2 } from '@/modules/negotiation/hooks/useGetNegotiationKeywordsV2Query';
import { useGetNegotiationQueryV2 } from '@/modules/negotiation/hooks/useGetNegotiationV2Query';
import { useUpdateNegotiationKeywordMutationV2 } from '@/modules/negotiation/hooks/useUpdateNegotiationKeywordMutationV2';
import { downloadFileFromUrl } from '@/modules/negotiation/utils/download-file-from-url';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { Button } from '@/modules/theme/components/Button/Button';
import { ButtonSkeletonLoader } from '@/modules/theme/components/Button/ButtonSkeletonLoader';
import { Editor } from '@/modules/theme/components/Editor/Editor';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { MaxWidth } from '@/modules/theme/components/MaxWidth';
import { PageHeader } from '@/modules/theme/components/PageHeader/PageHeader';
import { PageMeta } from '@/modules/theme/components/PageMeta';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';
import { Paragraph } from '@/modules/theme/components/Typography';
import { Text } from '@/modules/theme/components/Typography/Text';
import { dark } from '@/modules/theme/utils/colors';

import {
  ButtonsSectionStyled,
  ContainerStyled,
  DownloadButtonStyled,
  DownloadEmailLinksSectionStyled,
  DownloadEmailSectionStyled,
  EditorWrapperStyled,
  InfoWrapperContainer,
  TitleStyled,
} from './CPOLetterPage.styled';

const cpoLetterStruct = sup.object({
  cpoLetter: sup.string(),
  subject: sup.string(),
});

type CpoLetterStructType = sup.Infer<typeof cpoLetterStruct>;

export const CPOLetterPage = () => {
  const { t } = useTranslation('pages/CPOLetterPage');
  const navigate = useNavigate();

  const [introKeyword, setIntroKeyword] = useState<{
    id: string;
    value: string;
  }>();

  const [introSubjectKeyword, setIntroSubjectKeyword] = useState<{
    id: string;
    value: string;
  }>();

  const { negotiationId } = onboardedRoutes.useGetRouteParams('cpoLetter');

  const { data: negotiation, isPending } = useGetNegotiationQueryV2({
    negotiationId,
  });

  const { data: negotiationKeywords, isPending: isPendingKeyword } =
    useGetNegotiationKeywordsQueryV2({
      negotiationId,
    });

  const {
    mutate: updateNegotiationKeywordMutate,
    isPending: isUpdateNegotiationKeywordPending,
  } = useUpdateNegotiationKeywordMutationV2();

  const { refetch } = useGetNegotiationEmlFileV2Query({
    negotiationId,
  });

  const downloadEmlFile = async () => {
    try {
      const response = await refetch();

      if (response.isSuccess) {
        const url = window.URL.createObjectURL(response.data);
        const fileName = `${(
          negotiation?.attributes.name ?? 'negotiation'
        ).replace(/\s+/g, '-')}-cpo-mails.zip`;

        downloadFileFromUrl(url, fileName);
        window.URL.revokeObjectURL(url);
      } else {
        // eslint-disable-next-line no-console
        console.error('Failed to download file:', response.error);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error downloading file:', error);
    }
  };

  const formMethods = useForm({
    struct: cpoLetterStruct,
    defaultValues: {
      cpoLetter: '',
      subject: '',
    },
  });
  const {
    handleSubmit,
    register,
    reset,
    formState: { isDirty },
  } = formMethods;

  useEffect(() => {
    if (negotiationKeywords) {
      const invitationLetter = negotiationKeywords.find(
        (keyword) => keyword.name === 'introduction-message'
      );
      const invitationLetterSubject = negotiationKeywords.find(
        (keyword) => keyword.name === 'introduction-message-subject'
      );

      setIntroKeyword(invitationLetter);
      setIntroSubjectKeyword(invitationLetterSubject);

      reset({
        cpoLetter: invitationLetter?.value ?? '',
        subject: invitationLetterSubject?.value ?? '',
      });
    }
  }, [negotiationKeywords, reset]);

  const cpoLetterSubmit = ({ cpoLetter, subject }: CpoLetterStructType) => {
    if (introKeyword && cpoLetter !== introKeyword?.value) {
      updateNegotiationKeywordMutate({
        negotiationId,
        keywordId: introKeyword.id,
        value: {
          id: introKeyword.id,
          value: cpoLetter,
        },
      });
    }

    if (introSubjectKeyword && subject !== introSubjectKeyword?.value) {
      updateNegotiationKeywordMutate({
        negotiationId,
        keywordId: introSubjectKeyword.id,
        value: {
          id: introSubjectKeyword.id,
          value: subject,
        },
      });
    }

    reset({
      cpoLetter,
      subject,
    });
  };

  return (
    <AuthenticatedLayout>
      <PageMeta
        title={t('Invitation letter')}
        description={t('Invitation letter')}
      />
      <PageHeader text={t('Invitation letter')} />
      <MaxWidth>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(cpoLetterSubmit)}>
            <ContainerStyled>
              <EditorWrapperStyled>
                <TitleStyled variant={'h3'}>
                  {t('Edit Invitation letter')}
                </TitleStyled>
                {isPending || isPendingKeyword ? (
                  <SkeletonLoader height={448} />
                ) : (
                  <>
                    <TextInput
                      label={t('Cpo letter subject')}
                      {...register('subject')}
                    />
                    <Editor name={register('cpoLetter').name} />
                  </>
                )}
              </EditorWrapperStyled>
              <DownloadEmailSectionStyled>
                <TitleStyled variant={'h3'}>
                  {t('Download as email')}
                </TitleStyled>
                {isPending || isPendingKeyword ? (
                  <span>
                    <SkeletonLoader height={120} />
                  </span>
                ) : (
                  <Text color={dark[400]}>
                    {t(
                      'These email templates of your Invitation letter is generated in multiple files to make sure they are not detected as spam. This means that each mail has a set list of suppliers it will be sent to.'
                    )}
                  </Text>
                )}
                <DownloadEmailLinksSectionStyled>
                  {isPending || isPendingKeyword ? (
                    <span>
                      <SkeletonLoader height={26} />
                    </span>
                  ) : (
                    <FlexContainer justify="center" direction="column" gap={2}>
                      <DownloadButtonStyled
                        onClick={() => downloadEmlFile()}
                        variant="text"
                        disabled={
                          isDirty ||
                          !negotiation?.attributes.negotiationSuppliers?.length
                        }
                      >
                        <DownloadIcon />
                        {t('Download EML files')}
                      </DownloadButtonStyled>
                      <InfoWrapperContainer>
                        {!negotiation?.attributes.negotiationSuppliers
                          ?.length && (
                          <Paragraph>
                            {t(
                              'Note that there are no suppliers in this negotiation yet which means that there are no recipients to send an email to.'
                            )}
                          </Paragraph>
                        )}
                        {isDirty && (
                          <Paragraph>
                            {t(
                              'To download the EML files save the CPO letter first.'
                            )}
                          </Paragraph>
                        )}
                      </InfoWrapperContainer>
                    </FlexContainer>
                  )}
                </DownloadEmailLinksSectionStyled>
                <ButtonsSectionStyled>
                  {isPending || isUpdateNegotiationKeywordPending ? (
                    <ButtonSkeletonLoader />
                  ) : (
                    <Button
                      type="submit"
                      loading={isUpdateNegotiationKeywordPending}
                    >
                      {t('Save changes')}
                    </Button>
                  )}
                  {isPending || isUpdateNegotiationKeywordPending ? (
                    <ButtonSkeletonLoader />
                  ) : (
                    <Button variant={'outline'} onClick={() => navigate(-1)}>
                      {t('Back')}
                    </Button>
                  )}
                </ButtonsSectionStyled>
              </DownloadEmailSectionStyled>
            </ContainerStyled>
          </form>
        </FormProvider>
      </MaxWidth>
    </AuthenticatedLayout>
  );
};
