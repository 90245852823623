import { GroupBase, Props as ReactSelectProps } from 'react-select';

import { BasicOption } from './types';

export const findSelectValue = <
  Option extends BasicOption = BasicOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  currentValue,
  options,
  findByName = false,
}: {
  currentValue: string | number | null;
  options: ReactSelectProps<Option, IsMulti, Group>['options'];
  findByName?: boolean;
}) => {
  return options
    ?.flatMap((optionOrGroup) => {
      if ('value' in optionOrGroup) return optionOrGroup;
      return optionOrGroup.options;
    })
    .map(
      (option) =>
        ({
          label: option.label,
          value: option.value,
        }) as Option
    )
    .find((option) => {
      if (findByName) {
        return option.label === currentValue;
      }

      return option.value === currentValue;
    });
};
