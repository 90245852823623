import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as EditIcon } from '@/assets/icons/editIcon.svg';
import { AuthenticatedLayout } from '@/layouts/AuthenticatedLayout/AuthenticatedLayout';
import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { useGetSupplierLanguageLabels } from '@/modules/language/supplierLanguage/useGetSupplierLanguageLabels';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { LinkType } from '@/modules/theme/components/ButtonLink';
import { HeaderButtonLink } from '@/modules/theme/components/HeaderButton';
import { MaxWidth } from '@/modules/theme/components/MaxWidth';
import { PageHeader } from '@/modules/theme/components/PageHeader/PageHeader';
import { PageMeta } from '@/modules/theme/components/PageMeta';
import { Heading, Text } from '@/modules/theme/components/Typography';
import { formatAmount } from '@/utils/stringUtils';

import { TextWithLoader } from './components/TextWithLoader';
import { useGetSupplierQuery } from './hooks/useGetSupplierQuery';
import {
  BackButtonStyled,
  ContainerStyled,
  DataContainerStyled,
  PortfolioContentStyled,
  PortfolioStyled,
  SpendAmountStyled,
  SpendSkeletonLoaderStyled,
  SupplierInfoContentStyled,
  TextStyled,
} from './SupplierPage.styled';

export const SupplierPage = () => {
  const { t } = useTranslation('pages/SupplierPage');
  const navigate = useNavigate();
  const {
    customer: { currency },
  } = useSessionContext();
  const { supplierId } = onboardedRoutes.useGetRouteParams('supplier');

  const languageLabels = useGetSupplierLanguageLabels();

  const { isPending, data } = useGetSupplierQuery({
    supplierId,
  });

  return (
    <AuthenticatedLayout>
      <PageMeta title={t('Suppliers')} description={t('Suppliers')} />
      <PageHeader
        text={data?.name || ''}
        isLoading={isPending}
        buttonLink={
          supplierId ? (
            <HeaderButtonLink
              to={onboardedRoutes.buildUrl('editSupplier', {
                params: { supplierId },
              })}
              linkType={LinkType.Internal}
            >
              <TextStyled>{t('Edit')}</TextStyled>
              <EditIcon width="24" height="24" />
            </HeaderButtonLink>
          ) : undefined
        }
      />
      <MaxWidth>
        <ContainerStyled>
          <PortfolioContentStyled>
            <Heading variant={'h3'}>
              {t('Spend ({{currency}})', {
                currency,
              })}
            </Heading>
            <SpendAmountStyled>
              {isPending ? (
                <SpendSkeletonLoaderStyled />
              ) : (
                formatAmount(Number(data?.spend))
              )}
            </SpendAmountStyled>
            <PortfolioStyled />
          </PortfolioContentStyled>
          <SupplierInfoContentStyled>
            <DataContainerStyled>
              <Text bold>{t('Company')}</Text>
              <TextWithLoader loading={isPending}>{data?.name}</TextWithLoader>
            </DataContainerStyled>
            <DataContainerStyled>
              <Text bold>{t('Category')}</Text>
              <TextWithLoader loading={isPending}>
                {data?.categoriesHierarchy &&
                  getCategory(data.categoriesHierarchy)}
              </TextWithLoader>
            </DataContainerStyled>
            <DataContainerStyled>
              <Text bold>{t('Business unit')}</Text>
              <TextWithLoader loading={isPending}>
                {data?.businessUnit?.name}
              </TextWithLoader>
            </DataContainerStyled>
            <DataContainerStyled>
              <Text bold>{t('Org nr.')}</Text>
              <TextWithLoader loading={isPending}>
                {data?.organizationNumber}
              </TextWithLoader>
            </DataContainerStyled>
            {/* <NegotiationsContainerStyled>
              <NegotiationsLabelStyled bold>
                {t('Current negotiation')}
              </NegotiationsLabelStyled>
              {isPending ? (
                <NegotiationsSkeletonLoaderStyled />
              ) : data && data.currentNegotiations.length ? (
                data.currentNegotiations.map((currentNegotiation) => (
                  <InternalLink
                    to={onboardedRoutes.buildUrl('activeNegotiation', {
                      params: { negotiationId: currentNegotiation.id },
                      query: defaultPrefixedSuppliersQueryParams,
                    })}
                    key={currentNegotiation.id}
                  >
                    <Text>{currentNegotiation.title}</Text>
                  </InternalLink>
                ))
              ) : null}
            </NegotiationsContainerStyled>
            <NegotiationsContainerStyled>
              <NegotiationsLabelStyled bold>
                {t('Completed negotiations')}
              </NegotiationsLabelStyled>
              {isPending ? (
                <NegotiationsSkeletonLoaderStyled />
              ) : data && data.completedNegotiations.length ? (
                data.completedNegotiations.map((completedNegotiation) => (
                  <InternalLink
                    to={onboardedRoutes.buildUrl('completedNegotiation', {
                      params: { negotiationId: completedNegotiation.id },
                    })}
                    key={completedNegotiation.id}
                  >
                    <Text>{completedNegotiation.title}</Text>
                  </InternalLink>
                ))
              ) : null}
            </NegotiationsContainerStyled> */}
            <DataContainerStyled>
              <Text bold>{t('Contact (first name)')}</Text>
              <TextWithLoader loading={isPending}>
                {data?.selectedContactPerson?.firstName}
              </TextWithLoader>
            </DataContainerStyled>
            <DataContainerStyled>
              <Text bold>{t('Contact (last name)')}</Text>
              <TextWithLoader loading={isPending}>
                {data?.selectedContactPerson?.lastName}
              </TextWithLoader>
            </DataContainerStyled>
            <DataContainerStyled>
              <Text bold>{t('Language')}</Text>
              <TextWithLoader loading={isPending}>
                {data && languageLabels[data.language]}
              </TextWithLoader>
            </DataContainerStyled>
          </SupplierInfoContentStyled>
        </ContainerStyled>
        <BackButtonStyled variant={'outline'} onClick={() => navigate(-1)}>
          {t('Back')}
        </BackButtonStyled>
      </MaxWidth>
    </AuthenticatedLayout>
  );
};

type Category = {
  id: string;
  name: string;
};

const getCategory = (categoriesHierarchy: Category[]) => {
  return categoriesHierarchy
    .reverse()
    .map((category) => category.name)
    .join(' / ');
};
