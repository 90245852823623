import { format } from 'date-fns/format';
import { Calendar } from 'lucide-react';
import { useEffect, useState } from 'react';

import { Button } from '@/components/ui/button';
import { Calendar as CalendarComponent } from '@/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { blue } from '@/modules/theme/utils/colors';
import { cn } from '@/utils/utils';

interface DateRangePickerProps {
  startDate?: Date;
  endDate?: Date;
  noStartDateTitle?: string;
  onDateRangeChange?: (startDate: Date | undefined, endDate: Date) => void;
  className?: string;
}

export default function DateRangePicker({
  startDate: initialStartDate,
  endDate: initialEndDate,
  noStartDateTitle,
  onDateRangeChange,
  className,
}: DateRangePickerProps) {
  const defaultEndDate = new Date();

  const [startDate, setStartDate] = useState<Date | undefined>(
    initialStartDate
  );
  const [endDate, setEndDate] = useState<Date>(
    initialEndDate || defaultEndDate
  );
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [currentSelection, setCurrentSelection] = useState<
    'start' | 'end' | null
  >(null);

  useEffect(() => {
    setStartDate(initialStartDate);

    if (initialEndDate) {
      setEndDate(initialEndDate);
    }
  }, [initialStartDate, initialEndDate]);

  const handleDateSelect = (date: Date | undefined) => {
    if (currentSelection === 'start') {
      const newStartDate = date || startDate;
      setStartDate(newStartDate);
      setCurrentSelection('end');

      if (newStartDate && newStartDate > endDate) {
        setEndDate(newStartDate);
      }
    } else if (currentSelection === 'end') {
      if (!startDate) return;

      const newEndDate = date || endDate;

      if (newEndDate && newEndDate >= startDate) {
        setEndDate(newEndDate);
        setIsCalendarOpen(false);
        setCurrentSelection(null);

        onDateRangeChange?.(startDate, newEndDate);
      }
    }
  };

  const handleOpenChange = (open: boolean) => {
    setIsCalendarOpen(open);
    if (open) {
      setCurrentSelection('start');
    } else {
      setCurrentSelection(null);
      onDateRangeChange?.(startDate, endDate);
    }
  };

  return (
    <Popover open={isCalendarOpen} onOpenChange={handleOpenChange}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className={cn(
            'w-full justify-start text-left font-normal border-gray-300 border-2 h-14 px-4',
            'hover:bg-transparent hover:border-gray-300 focus:ring-0 focus-visible:ring-0 focus-visible:ring-offset-0',
            className
          )}
        >
          <Calendar className={`mr-4 h-5 w-5 text-[${blue[800]}]`} />
          <span className={`text-[${blue[800]}] font-bold`}>
            From:{' '}
            {startDate
              ? format(startDate, 'dd/MM/yy')
              : noStartDateTitle || 'Beginning'}
            <span className="mx-4">To: {format(endDate, 'dd/MM/yy')}</span>
          </span>
          <span className="ml-auto">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={`text-[${blue[800]}]`}
            >
              <path
                d="M6 9L12 15L18 9"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <div className="p-2">
          <CalendarComponent
            mode="single"
            selected={currentSelection === 'start' ? startDate : endDate}
            onSelect={handleDateSelect}
            initialFocus
            disabled={(date) => {
              if (!startDate && currentSelection === 'end') {
                return true;
              } else if (startDate && currentSelection === 'end') {
                return date < startDate || date > new Date();
              }

              return false;
            }}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
}
