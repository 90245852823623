import { useTranslation } from 'react-i18next';

import { findSelectValue } from '@/modules/form/components/Select/findSelectValue';
import { SelectInput } from '@/modules/form/components/Select/SelectInput/SelectInput';
import {
  NegotiationSupplierStateV2,
  NegotiationWithProgressSuppliersOrder,
} from '@/modules/negotiation/types/negotiationTypes';
import { SupplierBusinessUnitFilter } from '@/modules/suppliers/components/SuppliersFilters/SupplierBusinessUnitFilter';
import { SupplierCategoryFilter } from '@/modules/suppliers/components/SuppliersFilters/SupplierCategoryFilter';
import { SupplierSearchFilter } from '@/modules/suppliers/components/SuppliersFilters/SupplierSearchFilter';
import { SuppliersFiltersLayout } from '@/modules/suppliers/components/SuppliersFilters/SuppliersFiltersLayout';

import { SupplierProgressStepFilter } from './components/SupplierProgressStepFilter';

type SuppliersFiltersProps = {
  isLoading: boolean;
  searchQuery: string;
  progressStep: string;
  order: string;
  categoriesFilter: string[];
  onPageSizeChange: (pageSize: number) => void;
  onSearchQueryChange: (query: string) => void;
  onProgressStepChange: (
    value: NegotiationSupplierStateV2 | 'null' | undefined
  ) => void;
  onCategoryChange: (values: string[]) => void;
  onOrderChange: (order: NegotiationWithProgressSuppliersOrder) => void;
  onBusinessUnitChange: (values: string[]) => void;
  businessUnitFilter: string[];
  businessUnits?: string[];
};

export const SuppliersFilters = ({
  categoriesFilter,
  searchQuery,
  order,
  progressStep,
  businessUnits,
  businessUnitFilter,
  onProgressStepChange,
  onOrderChange,
  onCategoryChange,
  onSearchQueryChange,
  onBusinessUnitChange,
}: SuppliersFiltersProps) => {
  const { t } = useTranslation('modules/negotiations/NegotiationSuppliersList');

  const orderOptions: {
    label: string;
    value: NegotiationWithProgressSuppliersOrder;
  }[] = [
    {
      label: t('Name ascending'),
      value: 'name',
    },
    {
      label: t('Name descending'),
      value: '-name',
    },
    {
      label: t('Spend ascending'),
      value: 'currentSpend',
    },
    {
      label: t('Spend descending'),
      value: '-currentSpend',
    },
    {
      label: t('Progress step ascending'),
      value: 'negotiationProgress.state',
    },
    {
      label: t('Progress step descending'),
      value: '-negotiationProgress.state',
    },
    {
      label: t('Category name ascending'),
      value: 'category.name',
    },
    {
      label: t('Category name descending'),
      value: '-category.name',
    },
    {
      label: t('Contact email ascending'),
      value: 'defaultContact.email',
    },
    {
      label: t('Contact email descending'),
      value: '-defaultContact.email',
    },
  ];

  return (
    <SuppliersFiltersLayout>
      <SupplierSearchFilter
        searchQuery={searchQuery}
        onChange={onSearchQueryChange}
      />
      <SelectInput
        name="sortBy"
        label={t('Sort by')}
        options={orderOptions}
        showErrorMessage={false}
        value={findSelectValue({
          currentValue: order,
          options: orderOptions,
        })}
        placeholder={t('Choose...')}
        onChange={(option) => onOrderChange(option?.value || 'name')}
      />
      <SupplierProgressStepFilter
        progressStep={progressStep}
        onChange={onProgressStepChange}
      />
      <SupplierCategoryFilter
        filterValues={categoriesFilter}
        onChange={onCategoryChange}
      />
      {businessUnits && (
        <SupplierBusinessUnitFilter
          businessUnits={businessUnits}
          filterValues={businessUnitFilter}
          onChange={onBusinessUnitChange}
        />
      )}
    </SuppliersFiltersLayout>
  );
};
