import * as sup from 'superstruct';

import { JsonApiV2Client } from '@/modules/api/utils/createJsonApiV2Client';

import { getNegotiationSupplierResponsesPDFResourcePathV2 } from '../resources/negotiation/negotiationResourceConsts';

export type GetNegotiationSupplierResponsesPDFQueryParams = {
  negotiationId: string;
};

export const getNegotiationSupplierResponsesPDFQueryV2 = (
  jsonApi: JsonApiV2Client,
  queryParams: GetNegotiationSupplierResponsesPDFQueryParams
) => {
  return async () => {
    const result = await jsonApi.get({
      path: getNegotiationSupplierResponsesPDFResourcePathV2(),
      responseStruct: getNegotiationSupplierResponsesPDFResponseStructV2,
      queryParams,
      axiosConfig: {
        responseType: 'blob',
      },
    });

    return result.data as Blob;
  };
};

const getNegotiationSupplierResponsesPDFResponseStructV2 = sup.object({
  data: sup.any(),
});
