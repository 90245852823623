import styled from 'styled-components';

interface MaxWidthProps {
  maxWidth?: number | string;
  padding?: number | number[];
}
export const MaxWidth = styled('div')<MaxWidthProps>(
  ({ maxWidth, padding, theme: { spacing } }) => ({
    ...(maxWidth ? { maxWidth: `${maxWidth}px` } : {}),
    ...(padding ? { padding: spacing(padding) } : {}),
  })
);
