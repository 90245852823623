export const black = '#000000';
export const white = '#ffffff';

export const dark = {
  50: '#f2f2f2',
  60: '#dadada',
  70: '#d9d9d9',
  100: '#cccccc',
  200: '#aaaaaa',
  400: '#888888',
  600: '#666666',
  800: '#444444',
  900: '#222222',
};

export const mint = {
  200: '#A7A7A8',
  400: '#60d0da',
  500: '#56BBc7',
  600: '#8aadb3',
  800: '#4ba5b4',
  900: '#3c8490',
};

export const blue = {
  200: '#F4F7F9',
  400: '#346d96',
  600: '#1b5a7c',
  700: '#104c6a',
  800: '#234b67',
  900: '#17506b',
  1000: '#1a364c',
};

export const orange = {
  400: '#f7b559',
  600: '#f59c21',
  800: '#dc840a',
  1000: '#a06007',
};

export const red = {
  500: '#ffdbdc',
  800: '#ff4c52',
};

export const graphite = '#283840';

// hex transparency
export const halfTransparent = '80';
