import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

import { NegotiationLayout } from '@/layouts/NegotiationLayout/NegotiationLayout';
import { useGetNegotiationSupplierResponsesPDFQuery } from '@/modules/negotiation/hooks/useGetNegotiationSupplierResponsesPDFQuery';
import { useGetNegotiationQueryV2 } from '@/modules/negotiation/hooks/useGetNegotiationV2Query';
import { NegotiationStateV2 } from '@/modules/negotiation/types/negotiationTypes';
import { downloadFileFromUrl } from '@/modules/negotiation/utils/download-file-from-url';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { Box } from '@/modules/theme/components/Box';
import {
  HeaderButton,
  HeaderButtonLink,
} from '@/modules/theme/components/HeaderButton';
import { Tabs } from '@/modules/theme/components/Tabs/Tabs';

import { useNegotiationTabItems } from './hooks/use-negotiation-tab-items';

export const ANALYSIS_PAGE_ACTIVE_TAB = 'analysisPageActiveTab';

export const NegotiationTabLayout = () => {
  const { t } = useTranslation('pages/AnalysisAndOfferPage');
  const navigate = useNavigate();
  const tabs = useNegotiationTabItems();
  const { negotiationId } =
    onboardedRoutes.useGetRouteParams('activeNegotiation');
  const [zipAsUrl, setZipAsUrl] = useState<string>();

  const { data: negotiation, isPending: isNegotiationPending } =
    useGetNegotiationQueryV2({
      negotiationId,
    });

  const { refetch } = useGetNegotiationSupplierResponsesPDFQuery(
    { negotiationId },
    { enabled: false }
  );

  const downloadResponsesPdf = async () => {
    try {
      if (zipAsUrl) {
        window.URL.revokeObjectURL(zipAsUrl);
        setZipAsUrl('');
      }

      const response = await refetch();

      if (response.isSuccess) {
        const url = window.URL.createObjectURL(response.data);
        const fileName = `${
          negotiation?.attributes.name ?? 'suppliers-responses'
        }.zip`;

        setZipAsUrl(url);
        downloadFileFromUrl(url, fileName);
      } else {
        // eslint-disable-next-line no-console
        console.error('Failed to get file:', response.error);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error getting file:', error);
    }
  };

  return (
    <NegotiationLayout
      negotiationName={negotiation?.attributes.name}
      negotiationState={negotiation?.attributes.state as NegotiationStateV2}
      isLoading={isNegotiationPending}
      buttonLink={
        <div className="flex flex-col md:flex-row md:ml-auto md:space-x-2">
          {negotiation?.attributes.state &&
            [
              NegotiationStateV2.IN_PROGRESS,
              NegotiationStateV2.COMPLETED,
            ].includes(negotiation.attributes.state) && (
              <HeaderButton
                onClick={downloadResponsesPdf}
                disabled={
                  !negotiation.attributes.negotiationMetrics?.attributes.counts
                    .accepted
                }
              >
                <p className="px-4 whitespace-nowrap">
                  {t('Download accepted contracts PDF')}
                </p>
              </HeaderButton>
            )}
          <HeaderButtonLink
            to={onboardedRoutes.buildUrl('cpoLetter', {
              params: { negotiationId },
            })}
          >
            {t('Invitation letter')}
          </HeaderButtonLink>
          <HeaderButton onClick={() => navigate(-1)}>{t('Back')}</HeaderButton>
        </div>
      }
    >
      <Box mt={7} mb={7}>
        <Tabs tabs={tabs} variant="small" />
      </Box>
      <Outlet />
    </NegotiationLayout>
  );
};
