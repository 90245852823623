export const queryKeys = {
  user: {
    getUserDetails: 'getUserDetails',
    getCustomer: 'getCustomer',
    spendFile: {
      getSpendFiles: 'getSpendFiles',
      getSpendFileDownload: 'getSpendFileDownload',
    },
    serviceAgreement: {
      getServiceAgreement: 'getServiceAgreement',
      getServiceAgreementDownload: 'getServiceAgreementDownload',
    },
  },
  suppliers: {
    getSuppliers: 'getSuppliers',
    getSupplier: 'getSupplier',
    getSupplierCategories: 'getSupplierCategories',
    getChartSuppliersInfinite: 'getChartSuppliersInfinite',
  },
  customer: {
    getCustomerMetrics: 'getCustomerMetrics',
    getCustomerBusinessUnits: 'getCustomerBusinessUnits',
    getCustomerRecommendedSuppliers: 'getCustomerRecommendedSuppliers',
    useGetRecommendedCategoriesChartQuery:
      'useGetRecommendedCategoriesChartQuery',
    useGetExclusionReasonsChartQuery: 'useGetExclusionReasonsChartQuery',
    getCustomerSubscriptionPlans: 'getCustomerSubscriptionPlans',
    getActiveSubscriptionPlan: 'getActiveSubscriptionPlan',
  },
  agreement: {
    getAgreement: 'getAgreement',
    getAgreementFile: 'getAgreementFile',
  },
  negotiations: {
    getNegotiations: 'getNegotiations',
    getNegotiationsV2: 'getNegotiationsV2',
    getNegotiation: 'getNegotiation',
    getNegotiationV2: 'getNegotiationV2',
    getNegotiationContactV2: 'getNegotiationContactV2',
    getNegotiationKeywordsV2: 'getNegotiationKeywordsV2',
    getNegotiationMetrics: 'getNegotiationMetrics',
    getNegotiationEvents: 'getNegotiationEvents',
    getIntroEmails: 'getIntroEmails',
    getNegotiationEmlFile: 'getNegotiationEmlFile',
    getNegotiationSupplierResponsePDF: 'getNegotiationSupplierResponsePDF',
    getNegotiationSupplierResponsesPDF: 'getNegotiationSupplierResponsesPDF',
    getNegotiationSuppliers: 'getNegotiationSuppliers',
    getNegotiationSelectedSuppliersInfinite:
      'getNegotiationSelectedSuppliersInfinite',
    getNegotiationSuppliersInfinite: 'getNegotiationSuppliersInfinite',
    getKeywords: 'getKeywords',
    getSuggestedNegotiationMetrics: 'getSuggestedNegotiationMetrics',
    getSuggestedNegotiationMetricsV2: 'getSuggestedNegotiationMetricsV2',
    getStartedNegotiationSuppliers: 'getStartedNegotiationSuppliers',
    getNegotiationsDashboardMetrics: 'getNegotiationsDashboardMetrics',
    getNegotiationsSuppliersDashboardMetrics:
      'getNegotiationsSuppliersDashboardMetrics',
  },
} as const;

export const sortArrayQueryKey = <T extends unknown[] | undefined>(
  arrayQueryKey: T
) => {
  return [...(arrayQueryKey || [])].sort();
};
