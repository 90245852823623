import { Card } from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';

import { NegotiationType } from '../../types/negotiationTypes';

import BoxHeaderTitle from './box-header-title';

interface TermsCategory {
  title: string;
  count: number;
  isActive: boolean;
  negotiationType?: NegotiationType;
  invitedSuppliers?: number;
  acceptanceRate?: string;
}

interface OtherTermsBoxProps {
  termsCategories: TermsCategory[];
  className?: string;
}

const OtherTermsBox = ({ termsCategories, className }: OtherTermsBoxProps) => {
  return (
    <Card className={`rounded-md overflow-hidden ${className ?? ''}`}>
      <BoxHeaderTitle title="Other" />

      <div className="flex justify-around py-6 flex-wrap">
        {termsCategories.map((category, index) => (
          <>
            <div
              key={category.title}
              className="flex flex-1 min-w-[150px] flex-col items-center gap-2"
            >
              <div className="h-9 text-center">
                <span
                  className={`font-semibold text-base tracking-[-0.35px] ${
                    category.isActive ? 'text-[#234b67]' : 'text-[#aaaaaa]'
                  }`}
                >
                  {category.title}
                </span>
              </div>

              <div className="text-center">
                <span
                  className={`font-semibold text-[40px] tracking-[-0.88px] ${
                    category.isActive ? 'text-[#4ba5b4]' : 'text-[#aaaaaa]'
                  }`}
                >
                  {category.count}
                </span>
              </div>

              {category.negotiationType && (
                <div className="text-center">
                  <span
                    className={`font-semibold text-xs tracking-[-0.26px] ${
                      category.isActive ? 'text-[#4ba5b4]' : 'text-[#aaaaaa]'
                    }`}
                  >
                    {category.acceptanceRate ? 'Accepted' : 'Answered'}
                  </span>
                </div>
              )}

              <div className="text-center mb-6">
                <span
                  className={`font-semibold text-[12px] leading-normal tracking-[-0.22px] ${
                    category.isActive ? 'text-[#234b67]' : 'text-[#aaaaaa]'
                  }`}
                >
                  {category.invitedSuppliers && category.invitedSuppliers > 0
                    ? `${category.invitedSuppliers} invited suppliers`
                    : ''}
                  <br />
                  {category.acceptanceRate
                    ? `${category.acceptanceRate}% acceptance rate`
                    : ''}
                </span>
              </div>
            </div>
            {index < termsCategories.length - 1 && (
              <Separator
                orientation="vertical"
                className="h-[148px] bg-[#d9d9d9]"
              />
            )}
          </>
        ))}
      </div>
    </Card>
  );
};

export default OtherTermsBox;
